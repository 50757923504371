.owl-carousel .owl-stage {
    display: flex;
    align-items: center;
}

.owl-carousel {
    position: relative;
    height: 100%;

    div:not(.owl-controls) {
        height: 100%;
    }
    .slider-text {
        position: absolute;
        bottom: 4em;
        left: 2em;
        width: 20em;
        height: 8em;
    }
}


.owl-prev, .owl-next {
    width: 50px;
    height: 50px !important;
    position: absolute;
    top: 50%;
    display: block;
    margin-top: -25px;
    border-radius: 50%;
    i {
        color: #fff;
        font-size: 50px;
        position: absolute;
        top: -13%;

        transform: translateX(-50%);
    }
}
.owl-next {
    right: 20px;
    i {
        left: 50%;
    }
}

.owl-prev {
    left: 20px;
    i {
        right: 11%;
    }
}
.testimonial-slider.owl-carousel {
    div:not(.owl-controls) {
        height: unset;
    }
    .owl-next {
        right: -30px;
    }
    .owl-prev {
        left: -30px;
    }
}

.owl-item {
    width: 100vw;
}

.header-slider .owl-item img {
    height: calc(100vh - 56px);
}

.header-slider {
    margin-top: -60px;
}

.header-image {
    width: 100%;
    height: 100vh !important;
    position: relative;
}

.logo-slider,
.project-slider {
    img {
        height: auto;
    }
}

.header-img {
    overflow: hidden;
    position: relative;
}

.text-fade-in {
    position: absolute;
    top: 20%;
    left: 10%;
    z-index: 2;
    width: 40%;
    display: none;
    img {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        display: block;
    }
    p {
        color: #ffffff;
    }
}

.text-fade-in.no-style {
    position: unset;
    top: unset;
    left: unset;
    z-index: unset;
    width: unset;
    display: block;
    p {
        color: #ffffff;
    }
}

.scroll-wrapper {
    position: absolute;
    bottom: 20px;
    z-index: 9;
    left: 50%;
    width: 100px;
    height: 128px;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
    .mmouse {
        margin-bottom: -26px;
    }
    .scroll-text {
        font-size: 14px;
        margin-top: 8px;
    }
}

.background-image {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    height: 80vh !important;
}

.h-unset {
    height: unset !important;
}

.slider .owl-carousel .owl-item img {
    display: block;
    width: auto;
    max-height: 200px;
}


@media (max-width: 1024px) {
    .owl-carousel {
        .owl-item {
            img {
                width: initial !important;
                max-width: unset;
            }
        }
    }
    .testimonial-slider.owl-carousel {
        .owl-item {
            img {
                width: 100% !important;
                max-width: 100%;
            }
        }
    }
    .logo-slider,
    .project-slider {
        img {
            max-width: 200px !important;
        }
    }
}

@media (max-width: 992px) {
    .text-fade-in {
        top: 22%;
        left: 10%;
        width: 80%;
    }
    .owl-carousel {
        .owl-item {
            img {
                width: 100% !important;
            }
        }
    }
    .header-slider {
        .owl-item {
            img {
                height: 40vh !important;
            }
        }
    }
    .owl-prev, .owl-next {
        width: 30px;
        height: 30px !important;
        border: 2px solid #fff;
        i {
            font-size: 30px;
        }
    }
    .testimonial-slider.owl-carousel {
        .owl-next {
            right: -20px;
        }
        .owl-prev {
            left: -20px;
        }
    }

}

@media (max-width: 576px) {
    .background-image {
        height: 110vh !important;
    }
    .testimonial-slider.owl-carousel {
        .owl-next {
            right: 2px;
        }
        .owl-prev {
            left: 2px;
        }
    }
}
