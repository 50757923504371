@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,700");
.col-xs-offset-right-12 {
  margin-right: 100%;
}

.col-xs-offset-right-11 {
  margin-right: 91.66666667%;
}

.col-xs-offset-right-10 {
  margin-right: 83.33333333%;
}

.col-xs-offset-right-9 {
  margin-right: 75%;
}

.col-xs-offset-right-8 {
  margin-right: 66.66666667%;
}

.col-xs-offset-right-7 {
  margin-right: 58.33333333%;
}

.col-xs-offset-right-6 {
  margin-right: 50%;
}

.col-xs-offset-right-5 {
  margin-right: 41.66666667%;
}

.col-xs-offset-right-4 {
  margin-right: 33.33333333%;
}

.col-xs-offset-right-3 {
  margin-right: 25%;
}

.col-xs-offset-right-2 {
  margin-right: 16.66666667%;
}

.col-xs-offset-right-1 {
  margin-right: 8.33333333%;
}

.col-xs-offset-right-0 {
  margin-right: 0;
}

@media (min-width: 768px) {
  .col-sm-offset-right-12 {
    margin-right: 100%;
  }
  .col-sm-offset-right-11 {
    margin-right: 91.66666667%;
  }
  .col-sm-offset-right-10 {
    margin-right: 83.33333333%;
  }
  .col-sm-offset-right-9 {
    margin-right: 75%;
  }
  .col-sm-offset-right-8 {
    margin-right: 66.66666667%;
  }
  .col-sm-offset-right-7 {
    margin-right: 58.33333333%;
  }
  .col-sm-offset-right-6 {
    margin-right: 50%;
  }
  .col-sm-offset-right-5 {
    margin-right: 41.66666667%;
  }
  .col-sm-offset-right-4 {
    margin-right: 33.33333333%;
  }
  .col-sm-offset-right-3 {
    margin-right: 25%;
  }
  .col-sm-offset-right-2 {
    margin-right: 16.66666667%;
  }
  .col-sm-offset-right-1 {
    margin-right: 8.33333333%;
  }
  .col-sm-offset-right-0 {
    margin-right: 0;
  }
}

@media (min-width: 992px) {
  .col-md-offset-right-12 {
    margin-right: 100%;
  }
  .col-md-offset-right-11 {
    margin-right: 91.66666667%;
  }
  .col-md-offset-right-10 {
    margin-right: 83.33333333%;
  }
  .col-md-offset-right-9 {
    margin-right: 75%;
  }
  .col-md-offset-right-8 {
    margin-right: 66.66666667%;
  }
  .col-md-offset-right-7 {
    margin-right: 58.33333333%;
  }
  .col-md-offset-right-6 {
    margin-right: 50%;
  }
  .col-md-offset-right-5 {
    margin-right: 41.66666667%;
  }
  .col-md-offset-right-4 {
    margin-right: 33.33333333%;
  }
  .col-md-offset-right-3 {
    margin-right: 25%;
  }
  .col-md-offset-right-2 {
    margin-right: 16.66666667%;
  }
  .col-md-offset-right-1 {
    margin-right: 8.33333333%;
  }
  .col-md-offset-right-0 {
    margin-right: 0;
  }
}

@media (min-width: 1200px) {
  .col-lg-offset-right-12 {
    margin-right: 100%;
  }
  .col-lg-offset-right-11 {
    margin-right: 91.66666667%;
  }
  .col-lg-offset-right-10 {
    margin-right: 83.33333333%;
  }
  .col-lg-offset-right-9 {
    margin-right: 75%;
  }
  .col-lg-offset-right-8 {
    margin-right: 66.66666667%;
  }
  .col-lg-offset-right-7 {
    margin-right: 58.33333333%;
  }
  .col-lg-offset-right-6 {
    margin-right: 50%;
  }
  .col-lg-offset-right-5 {
    margin-right: 41.66666667%;
  }
  .col-lg-offset-right-4 {
    margin-right: 33.33333333%;
  }
  .col-lg-offset-right-3 {
    margin-right: 25%;
  }
  .col-lg-offset-right-2 {
    margin-right: 16.66666667%;
  }
  .col-lg-offset-right-1 {
    margin-right: 8.33333333%;
  }
  .col-lg-offset-right-0 {
    margin-right: 0;
  }
}

.footer {
  height: auto;
  color: #ffffff;
  background-color: #333333;
  padding-top: 35px;
  font-size: 0.9em;
}

.footer p {
  margin-bottom: 0;
}

.footer .col-5 p, .footer .col-10 p {
  line-height: 30px;
  font-size: 1.1em;
}

.facebook-feed {
  position: relative;
  width: 305px;
  height: 620px;
}

.fb_iframe_widget {
  display: inline-block;
  position: absolute;
  left: 0;
  top: 17px;
  border-radius: 100%;
}

.fb_iframe_widget iframe {
  border-radius: 100px;
}

.facebook-feed img {
  margin-left: auto;
  margin-right: auto;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
}

.project .project-slider {
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: #ccc;
}

.project .owl-prev i, .project .owl-next i {
  color: #000;
}

.loading {
  display: none;
}

#loadmore {
  background-color: #e46e00;
  border-radius: 50px;
  font-weight: 600;
  font-size: 1.2em;
}

#loadmore:hover {
  background-color: rgba(228, 110, 0, 0.65);
  color: #fff;
}

#top {
  color: #000;
}

.news .col-md-6:nth-child(odd):before {
  content: '';
  height: 511px;
  width: 3px;
  z-index: 10;
  position: absolute;
  background-color: #000;
  left: 100%;
  transform: translate(-50%, 0);
}

.news .container.mt-5.text-center h2:after {
  content: '';
  height: 26px;
  width: 27px;
  border-radius: 25px;
  z-index: 11;
  position: absolute;
  background-color: #000;
  transform: translate(-180%, 370%);
}

.news .title, .projects .title, .gallery .title {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  font-size: 50px;
  color: white;
  text-shadow: #000 2px 2px;
}

.news .cards, .projects .cards, .gallery .cards {
  margin-top: 50px;
  margin-bottom: 50px;
}

.news .cards .card-img-size, .projects .cards .card-img-size, .gallery .cards .card-img-size {
  height: 320px;
}

.news .cards .card-img-size .card-img-top, .projects .cards .card-img-size .card-img-top, .gallery .cards .card-img-size .card-img-top {
  width: 70%;
}

.news .cards .card-img-size div, .projects .cards .card-img-size div, .gallery .cards .card-img-size div {
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin: 0 auto;
}

.news .cards .card-img-size a, .projects .cards .card-img-size a, .gallery .cards .card-img-size a {
  position: absolute;
  top: 58%;
  left: 50%;
  transform: translate(-50%, 0);
  background-color: #fff;
  color: #000;
  font-weight: bold;
  font-size: 1em;
  padding: 10px 50px;
  border-bottom: 5px solid #000;
}

.news .cards .card-img-size a:hover, .projects .cards .card-img-size a:hover, .gallery .cards .card-img-size a:hover {
  background-color: #fd7e14;
  border-bottom: 2px solid #000;
  color: #fff;
}

.news .cards .card-body, .projects .cards .card-body, .gallery .cards .card-body {
  height: 150px;
  position: relative;
  margin-bottom: 25px;
  color: #000;
}

.news .cards .card-body .card-text, .projects .cards .card-body .card-text, .gallery .cards .card-body .card-text {
  padding: 0px 85px;
}

.article {
  margin: 50px 0;
}

.article img {
  width: 70%;
}

.article h2 {
  margin: 40px 0;
}

.article .tags a {
  background-color: #f1f1f1;
  padding: 5px;
  border: 1px solid #0086b3;
  margin-right: 5px;
  color: #0086b3;
}

.divider {
  background-repeat: no-repeat;
  background-size: cover;
}

.divider .fb-page {
  padding: 0 !important;
}

.divider h2 {
  font-weight: normal;
}

.divider h2, .divider h3 {
  color: #fff;
}

.divider a {
  background-color: #0081e4;
  border-radius: 0;
}

.fb-comments {
  width: 100%;
}

@media (max-width: 1024px) {
  .news .container.mt-5.text-center h2:after {
    transform: translate(-200%, 370%);
  }
}

@media (max-width: 992px) {
  .news .container.mt-5.text-center h2:after {
    transform: translate(-180%, 370%);
  }
  .card-img-size {
    height: 230px !important;
  }
  .card-img-size a {
    top: 50% !important;
  }
  .card-text {
    padding: 0px 20px !important;
  }
}

@media (max-width: 576px) {
  .news .cards .card-img-size div, .projects .cards .card-img-size div {
    height: 90%;
    width: 90%;
    margin: auto;
  }
  .news .cards .card-img-size a, .projects .cards .card-img-size a {
    top: 46% !important;
  }
  .news .divider .device-wrapper, .projects .divider .device-wrapper {
    display: none;
  }
  .news .col-md-6:nth-child(odd):before {
    display: none;
  }
  .container.mt-5.text-center h2:after {
    display: none;
  }
}

.gallery .container {
  margin-top: 50px;
  margin-bottom: 50px;
}

.gallery .container .gal-col {
  padding: 0;
}

.gallery .container .gal-col div {
  height: 300px;
  background-size: cover;
  background-position: center;
}

.gallery .container .gal-col .overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .5s ease;
  background-color: #e46e00;
}

.gallery .container .gal-col .overlay img {
  width: auto;
  left: 50%;
  top: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}

.gallery .container .gal-col:hover .overlay {
  opacity: 0.6;
}

.top-nav {
  padding-top: 10px;
  padding-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.navbar-brand {
  color: #000;
  font-weight: 900;
}

.navbar-brand:hover {
  color: #e46e00;
}

.container-fluid {
  margin-top: 140px;
}

.nav {
  background-color: white;
  padding: 5px;
}

.navbar-nav {
  width: 100%;
}

.navbar-light .navbar-nav .nav-link {
  color: #000;
}

.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-brand:hover, .navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-brand:focus {
  color: #000000;
}

.nav-item {
  margin: 0px 6px;
}

.nav-link {
  padding: .2rem 1rem;
  font-family: 'Source Sans Pro', sans-serif;
}

.nav-item {
  text-decoration: none;
}

.nav-item:first-child {
  margin-left: auto;
}

.nav-item:hover .nav-link {
  color: #a73c96 !important;
}

.nav-item .nav-link {
  color: #000;
}

.dropdown-menu {
  padding: 0 0;
}

.dropdown-menu .dropdown-item {
  text-decoration: none;
}

.dropdown-menu .dropdown-item:active {
  background-color: #a73c96;
}

.dropdown-menu .dropdown-item:focus {
  outline: none;
}

.dropdown-menu .dropdown-item:hover {
  background-color: #a73c96;
  color: #ffffff;
}

.nav-item:last-child {
  background-color: #a73c96;
  padding: 0px 5px;
  border: 2px solid #a73c96;
}

.nav-item:last-child .nav-link {
  color: #ffffff !important;
}

.nav-item:last-child:hover {
  background-color: transparent;
  border: 2px solid #a73c96;
}

.nav-item:last-child:hover .nav-link {
  color: #000000 !important;
}

.active-menu {
  color: #a73c96 !important;
}

@media (max-width: 992px) {
  .nav {
    position: fixed;
    width: 110vw;
    left: 0;
    right: 0;
  }
  .nav .nav-item:last-child {
    padding: 5px;
  }
  .fixed-top {
    top: 0 !important;
  }
  .nav-item:first-child {
    margin-left: 10px;
  }
  .active-menu:after {
    display: none;
  }
}

.owl-carousel .owl-stage {
  display: flex;
  align-items: center;
}

.owl-carousel {
  position: relative;
  height: 100%;
}

.owl-carousel div:not(.owl-controls) {
  height: 100%;
}

.owl-carousel .slider-text {
  position: absolute;
  bottom: 4em;
  left: 2em;
  width: 20em;
  height: 8em;
}

.owl-prev, .owl-next {
  width: 50px;
  height: 50px !important;
  position: absolute;
  top: 50%;
  display: block;
  margin-top: -25px;
  border-radius: 50%;
}

.owl-prev i, .owl-next i {
  color: #fff;
  font-size: 50px;
  position: absolute;
  top: -13%;
  transform: translateX(-50%);
}

.owl-next {
  right: 20px;
}

.owl-next i {
  left: 50%;
}

.owl-prev {
  left: 20px;
}

.owl-prev i {
  right: 11%;
}

.testimonial-slider.owl-carousel div:not(.owl-controls) {
  height: unset;
}

.testimonial-slider.owl-carousel .owl-next {
  right: -30px;
}

.testimonial-slider.owl-carousel .owl-prev {
  left: -30px;
}

.owl-item {
  width: 100vw;
}

.header-slider .owl-item img {
  height: calc(100vh - 56px);
}

.header-slider {
  margin-top: -60px;
}

.header-image {
  width: 100%;
  height: 100vh !important;
  position: relative;
}

.logo-slider img,
.project-slider img {
  height: auto;
}

.header-img {
  overflow: hidden;
  position: relative;
}

.text-fade-in {
  position: absolute;
  top: 20%;
  left: 10%;
  z-index: 2;
  width: 40%;
  display: none;
}

.text-fade-in img {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.text-fade-in p {
  color: #ffffff;
}

.text-fade-in.no-style {
  position: unset;
  top: unset;
  left: unset;
  z-index: unset;
  width: unset;
  display: block;
}

.text-fade-in.no-style p {
  color: #ffffff;
}

.scroll-wrapper {
  position: absolute;
  bottom: 20px;
  z-index: 9;
  left: 50%;
  width: 100px;
  height: 128px;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
}

.scroll-wrapper .mmouse {
  margin-bottom: -26px;
}

.scroll-wrapper .scroll-text {
  font-size: 14px;
  margin-top: 8px;
}

.background-image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 80vh !important;
}

.h-unset {
  height: unset !important;
}

.slider .owl-carousel .owl-item img {
  display: block;
  width: auto;
  max-height: 200px;
}

@media (max-width: 1024px) {
  .owl-carousel .owl-item img {
    width: initial !important;
    max-width: unset;
  }
  .testimonial-slider.owl-carousel .owl-item img {
    width: 100% !important;
    max-width: 100%;
  }
  .logo-slider img,
  .project-slider img {
    max-width: 200px !important;
  }
}

@media (max-width: 992px) {
  .text-fade-in {
    top: 22%;
    left: 10%;
    width: 80%;
  }
  .owl-carousel .owl-item img {
    width: 100% !important;
  }
  .header-slider .owl-item img {
    height: 40vh !important;
  }
  .owl-prev, .owl-next {
    width: 30px;
    height: 30px !important;
    border: 2px solid #fff;
  }
  .owl-prev i, .owl-next i {
    font-size: 30px;
  }
  .testimonial-slider.owl-carousel .owl-next {
    right: -20px;
  }
  .testimonial-slider.owl-carousel .owl-prev {
    left: -20px;
  }
}

@media (max-width: 576px) {
  .background-image {
    height: 110vh !important;
  }
  .testimonial-slider.owl-carousel .owl-next {
    right: 2px;
  }
  .testimonial-slider.owl-carousel .owl-prev {
    left: 2px;
  }
}

.openingstijden table {
  width: 100%;
}

.openingstijden table, .openingstijden tr, .openingstijden td, .openingstijden th {
  border: 2px solid;
  padding: 10px;
}

td {
  text-align: left;
}

.btn-primary {
  background-color: #333333;
  color: #fff;
  border-radius: 0;
  border: 0px;
}

.btn-primary:hover {
  background-color: #e46e00;
}

.btn-blue {
  background-color: #0091d3;
  border: 1px solid #0091d3;
  -webkit-box-shadow: 0px -2px 87px -11px black;
  -moz-box-shadow: 0px -2px 87px -11px black;
  box-shadow: 0px -2px 87px -11px black;
  margin-top: 25px;
}

.btn-blue-2 {
  background-color: #0091d3;
  border: 1px solid #0091d3;
}

.btn-red {
  background-color: #f53c3c;
  border: 1px solid #f53c3c;
}

.btn-yellow {
  background-color: #f8de00;
  border: 1px solid #f8de00;
  color: #000;
}

.btn-purple {
  background-color: #A73C96;
  border: 1px solid #A73C96;
  position: relative;
  z-index: 99;
}

.btn-custom {
  padding: 10px 30px;
  border-radius: 25px;
  font-weight: bold;
  width: auto;
}

.btn-custom:hover {
  background-color: transparent;
  cursor: pointer;
  color: #000000;
}

.newsletter-wrapper .btn-custom:hover, .header-slider .btn-custom:hover {
  color: #ffffff;
}

.btn-purple {
  border-radius: 0;
}

html, body {
  overflow-x: hidden;
}

body {
  background-color: #ffffff;
}

p, h1, h2, h3, h4, h5, h6, span, label {
  font-family: 'Source Sans Pro', sans-serif;
}

p {
  font-size: 1.1rem;
}

ul li strong {
  color: #312F83;
}

.filter {
  filter: brightness(0) invert(1);
}

h1, h2, h3, h4, h5 {
  font-weight: bold;
}

.text-last-right {
  text-align-last: right;
}

.form-control {
  border: 2px solid #ced4da;
}

.newsletter-wrapper {
  position: relative;
  background-image: url(/assets/img/contact-change.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.overlay-right {
  position: absolute;
  width: 100%;
  height: 100%;
  max-height: 70%;
  top: 0;
  right: -30px;
}

.overlay-right .text {
  color: #ffffff;
  position: absolute;
  top: 30px;
  left: 30px;
  width: 250px;
}

.overlay-right .text h4 {
  font-size: 40px;
}

.text-fade-in h1 {
  font-size: 90px;
  color: #ffffff;
}

.overlay-left {
  position: absolute;
  width: 100%;
  height: 100%;
  max-height: 70%;
  top: 0;
  right: -30px;
}

.overlay-left .text {
  color: #ffffff;
  position: absolute;
  top: 30px;
  left: 30px;
  width: 250px;
}

.overlay-left .text h4 {
  font-size: 40px;
}

.img-border {
  border: 4px solid #74ae00;
}

.text-green {
  color: #74ae00;
}

.text-blue {
  color: #312f83;
}

a {
  color: #000000;
  text-decoration: underline;
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  -ms-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}

.pink {
  color: #CA8AC0 !important;
}

.contact-gegevens a {
  color: #000000;
}

a:hover {
  color: #F8DE00;
  text-decoration: none;
}

.footer a {
  color: #ffffff;
  text-decoration: none;
}

.h200 {
  height: 200px;
}

.footer a:hover {
  color: #A73C96;
  text-decoration: underline;
}

.btn-custom {
  color: #fff;
  text-decoration: none;
}

.btn-custom:focus {
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.nav-link {
  text-decoration: none;
}

.paralax-background {
  height: 500px;
  background: no-repeat fixed center bottom;
  background-size: cover;
}

.img {
  max-width: 200px;
  max-height: 120px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.email {
  word-wrap: break-word;
}

#calendar {
  width: 100%;
  min-height: 500px;
  margin: 100px auto;
}

#newsletter form input, #newsletter form button {
  display: block;
  width: 100%;
  margin-top: 20px;
}

#newsletter form input:focus {
  border: 2px solid #007bff;
  outline: none;
  box-shadow: none;
}

#newsletter form button {
  background-color: #007bff;
  border: 2px solid #007bff;
  color: #fff;
}

#newsletter form button:hover {
  cursor: pointer;
  background-color: transparent;
  color: #007bff;
}

#newsletter {
  margin-top: -110px;
}

.no-transparant-hover:hover {
  background-color: #0091D3;
}

#map {
  height: 400px;
  width: 100%;
}

.form-control:focus {
  border-color: #CED4DA;
  box-shadow: none;
}

.fb-chat {
  position: fixed;
  left: 2%;
  bottom: 10%;
  text-align: center;
}

.fb-chat img {
  width: 50px;
  height: 50px;
}

.fb-chat p {
  color: #2988FF;
  font-weight: bold;
}

.whap-chat {
  position: fixed;
  right: 2%;
  bottom: 10%;
  text-align: center;
}

.whap-chat img {
  width: 50px;
  height: 50px;
}

.whap-chat p {
  color: #000;
  font-weight: bold;
}

.grey-border {
  border: 2px solid #f2f2f2;
}

.taxonomy .tags a {
  background-color: #f1f1f1;
  padding: 5px;
  border: 1px solid #0086b3;
  margin-right: 5px;
  color: #0086b3;
}

.no-padding {
  margin: 0;
}

.text-black {
  color: #000000 !important;
}

.core-value .owl-prev {
  left: -50px;
}

.core-value .owl-next {
  right: -50px;
}

.custom-img {
  width: 70px;
  border-radius: 45px;
}

.slider {
  min-height: 350px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.h-70p {
  height: 70%;
}

.custom-margin {
  margin-top: 19%;
}

@media (max-width: 1200px) {
  .text-fade-in {
    top: 10%;
    width: 80%;
  }
}

@media (max-width: 992px) {
  .pdf-viewer {
    margin-top: 65px;
  }
  p {
    font-size: 1rem;
  }
  .whap-chat,
  .fb-chat {
    display: none;
  }
  .news .col-md-6:nth-child(odd):before {
    height: 425px;
  }
  .text-fade-in {
    top: 20%;
  }
}

@media (max-width: 576px) {
  .fc h2 {
    font-size: 2.4rem;
  }
  .fc button {
    /* planner*/
    font-size: 0.9em !important;
  }
  h2 {
    font-size: 1.3rem;
  }
  #newsletter {
    display: none;
  }
  .text-fade-in {
    top: 24%;
  }
  .text-fade-in h1 {
    font-size: 40px;
  }
  .custom-margin {
    margin-top: -48%;
  }
  .custom-height {
    height: 510px !important;
  }
}

@media (max-width: 320px) {
  .fc button {
    /* planner*/
    font-size: 0.7em !important;
  }
}
