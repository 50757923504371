//************ FOOTER ************\\

.footer {
    height: auto;
    color: #ffffff;
    background-color: #333333;
    padding-top: 35px;
    font-size: 0.9em;
    p {
        margin-bottom: 0;
    }
    .col-5, .col-10 {
        p {
            line-height: 30px;
            font-size: 1.1em;
        }
        a {
        }
    }
}
.facebook-feed{
    position: relative;
    width:305px;
    height:620px;
}

.fb_iframe_widget {
    display: inline-block;
    position: absolute;
    left: 0;
    top: 17px;
    border-radius: 100%;
}

.fb_iframe_widget iframe{
    border-radius: 100px;
}

.facebook-feed img{
    margin-left: auto;
    margin-right: auto;
    display: block;
    position: absolute;
    top:0;
    left:0;
    pointer-events: none;
}
