//************ PROJECTS ************\\
.project {
    .project-slider {
        padding-top: 50px;
        padding-bottom: 50px;

        background-color: #ccc;
    }
    .owl-prev i, .owl-next i{
        color: #000;
    }
}

.loading{
  display:none;
}

#loadmore{
  background-color: #e46e00;
  border-radius: 50px;
  font-weight: 600;
  font-size: 1.2em;
}

#loadmore:hover{
  background-color: rgba(228, 110, 0, 0.65);
  color: #fff;
}
#top{ color:#000;}


//************ NEWS&PROJECTS ************\\
.news{
  .col-md-6:nth-child(odd):before {
    content: '';
    height: 511px;
    width: 3px;
    z-index: 10;
    position: absolute;
    background-color: #000;
    left: 100%;
    transform: translate(-50%,0);
  }
  .container.mt-5.text-center{
    h2:after {
      content: '';
      height: 26px;
      width: 27px;
      border-radius: 25px;
      z-index: 11;
      position: absolute;
      background-color: #000;
      transform: translate(-180%, 370%);
    }
  }
}

.news, .projects, .gallery {
    .title{
        position: relative;
        top:50%;
        transform: translateY(-50%);
        font-size:50px;
        color:white;
        text-shadow: #000 2px 2px;
    }
    .card-wrapper{
        // background-color:#f2f2f2;
    }

    .cards {
        margin-top: 50px;
        margin-bottom: 50px;
        .card-img-size {
            height: 320px;
            .card-img-top{
              width: 70%;
            }
            div{
                height:100%;
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center;
                margin:0 auto;
            }
            a{
              position: absolute;
              top: 58%;
              left: 50%;
              transform: translate(-50%,0);
              background-color: #fff;
              color: #000;
              font-weight: bold;
              font-size: 1em;
              padding: 10px 50px;
              border-bottom:5px solid #000;
            }
            a:hover{
              background-color: #fd7e14;
              border-bottom:2px solid #000;
              color: #fff;
            }
        }
        .card-body{
            height:150px;
            position: relative;
            margin-bottom:25px;
            color: #000;
            .card-text{
              padding: 0px 85px;
            }
        }
    }
}

.article{
    margin: 50px 0;
    img{
        width:70%;
    }
    h2{
        margin: 40px 0;
    }
    .tags {
        a {
            background-color: #f1f1f1;
            padding:5px;
            border:1px solid #0086b3;
            margin-right:5px;
            color:#0086b3;
        }
    }
}

.divider{
  background-repeat: no-repeat;
  background-size: cover;
  .fb-page{
    padding:0 !important;
  }
  h2{
    font-weight: normal;
  }
  h2, h3{
    color: #fff;
  }
  a{
    background-color: #0081e4;
    border-radius: 0;
  }
}

.fb-comments{
  width: 100%;
}

@media (max-width: 1024px) {
  .news{
  }
  .news .container.mt-5.text-center h2:after {
    transform: translate(-200%, 370%);
}

}

@media (max-width: 992px) {
  .news .container.mt-5.text-center h2:after {
    transform: translate(-180%, 370%);
  }
  .card-img-size {
    height: 230px!important;
    a{
      top: 50%!important;
    }
  }
  .card-text{
      padding: 0px 20px!important;
  }
}

 @media (max-width: 576px) {
   .news, .projects{
     .cards {
         .card-img-size {
             div{
                 height:90%;
                 width: 90%;
                 margin:auto;
             }
             a{
               top:46%!important;
             }
           }
         }
         .divider{
             .device-wrapper{
               display: none;
             }
         }
       }
       .news{
         .col-md-6:nth-child(odd):before {
           display: none;
         }
       }
       .container.mt-5.text-center{
         h2:after {
           display: none;
         }
       }
}
