.gallery{
  .container{
    margin-top: 50px;
    margin-bottom: 50px;
    .gal-col{
      padding:0;
      div{
        height: 300px;
        background-size: cover;
        background-position: center;
      }
      .overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100%;
        width: 100%;
        opacity: 0;
        transition: .5s ease;
        background-color: #e46e00;
        img{
          width: auto;
          left: 50%;
          top: 50%;
          position: absolute;
          transform: translate(-50%,-50%);
        }
      }
    }
    .gal-col:hover{
      .overlay {
       opacity: 0.6;
     }
    }
  }
}
