@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,700');
@import 'offset-right';
@import 'footer';
@import 'news_projects';
@import 'gallery';
@import 'nav';
@import 'owlslider';
@import 'contact';
@import "buttons";

html, body{
    overflow-x: hidden;
}

body {
    background-color: #ffffff
}

p, h1, h2, h3, h4, h5, h6, span, label {
    font-family: 'Source Sans Pro', sans-serif;
}

p {
    font-size: 1.1rem;
}

ul li strong {
    color: #312F83;
}

.filter {
    filter: brightness(0) invert(1);
}

h1, h2, h3, h4, h5 {
    font-weight: bold;
}

.text-last-right {
    text-align-last: right;
}

.form-control {
    border: 2px solid #ced4da;
}

.newsletter-wrapper {
    position: relative;
    background-image: url(/assets/img/contact-change.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.overlay-right {
    position: absolute;
    width: 100%;
    height: 100%;
    max-height: 70%;
    top: 0;
    right: -30px;
    .text {
        color: #ffffff;
        position: absolute;
        top: 30px;
        left: 30px;
        width: 250px;
        h4 {
            font-size: 40px;
        }
    }
}

.text-fade-in {
    h1 {
        font-size: 90px;
        color: #ffffff;
    }
}

.overlay-left {
    position: absolute;
    width: 100%;
    height: 100%;
    max-height: 70%;
    top: 0;
    right: -30px;
    .text {
        color: #ffffff;
        position: absolute;
        top: 30px;
        left: 30px;
        width: 250px;
        h4 {
            font-size: 40px;
        }
    }
}

.img-border {
    border: 4px solid #74ae00;
}

.text-green {
    color: #74ae00;
}

.text-blue {
    color: #312f83;
}

a {
    color: #000000;
    text-decoration: underline;
    -webkit-transition: all 0.2s ease-in;
    -moz-transition: all 0.2s ease-in;
    -ms-transition: all 0.2s ease-in;
    -o-transition: all 0.2s ease-in;
    transition: all 0.2s ease-in;
}

.pink {
    color: #CA8AC0 !important;
}

.contact-gegevens a {
    color: #000000;
}

a:hover {
    color: #F8DE00;
    text-decoration: none;
}

.footer a {
    color: #ffffff;
    text-decoration: none;
}

.h200 {
    height: 200px;
}

.footer a:hover {
    color: #A73C96;
    text-decoration: underline;
}

.btn-custom {
    color: #fff;
    text-decoration: none;
}

.btn-custom:focus {
    outline: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.nav-link {
    text-decoration: none;
}

.paralax-background {
    height: 500px;
    background: no-repeat fixed center bottom;
    background-size: cover;
}

.img {
    max-width: 200px;
    max-height: 120px;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.email {
    word-wrap: break-word;
}

#calendar {
    width: 100%;
    min-height: 500px;
    margin: 100px auto;
}

#newsletter form input, #newsletter form button {
    display: block;
    width: 100%;
    margin-top: 20px;
}

#newsletter form input:focus {
    border: 2px solid #007bff;
    outline: none;
    box-shadow: none;
}

#newsletter form button {
    background-color: #007bff;
    border: 2px solid #007bff;
    color: #fff;
}

#newsletter form button:hover {
    cursor: pointer;
    background-color: transparent;
    color: #007bff;
}

#newsletter {
    margin-top: -110px;
}

.no-transparant-hover:hover {
    background-color: #0091D3;
}

#map {
    height: 400px;
    width: 100%;
}

.form-control:focus {
    border-color: #CED4DA;
    box-shadow: none;
}

//************ DEFAULT ************\\
.fb-chat {
    position: fixed;
    left: 2%;
    bottom: 10%;
    text-align: center;
    img {
        width: 50px;
        height: 50px;
    }
    p {
        color: #2988FF;
        font-weight: bold;

    }
}

.whap-chat {
    position: fixed;
    right: 2%;
    bottom: 10%;
    text-align: center;
}

.whap-chat img {
    width: 50px;
    height: 50px;
}

.whap-chat p {
    color: #000;
    font-weight: bold;
}

.grey-border {
    border: 2px solid #f2f2f2;
}

//************ taxonomy ************\\
.taxonomy {
    .tags {
        a {
            background-color: #f1f1f1;
            padding: 5px;
            border: 1px solid #0086b3;
            margin-right: 5px;
            color: #0086b3;
        }
    }
}

.no-padding {
    margin: 0;
}

.text-black {
    color: #000000 !important;
}

.core-value {
    .owl-prev{
     left: -50px;
    }
    .owl-next {
        right: -50px;
    }
}

.custom-img {
    width: 70px;
    border-radius: 45px;
}

.slider {
    min-height: 350px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.h-70p {
    height: 70%;
}

.custom-margin {
    margin-top: 19%;
}

    //************  ************\\

    //************ media-queries ************\\
    @media (max-width: 1200px) {
        .text-fade-in {
            top: 10%;
            width: 80%;
        }
    }

    @media (max-width: 992px) {
        .pdf-viewer {
            margin-top: 65px;
        }
        p {
            font-size: 1rem;
        }
        .whap-chat,
        .fb-chat {
            display: none;
        }
        .news .col-md-6:nth-child(odd):before {
            height: 425px;
        }
        .text-fade-in {
            top: 20%;
        }
    }

    @media (max-width: 576px) {
        .fc {
            h2 {
                font-size: 2.4rem;
            }
            button { /* planner*/
                font-size: 0.9em !important;
            }
        }
        h2 {
            font-size: 1.3rem;
        }
        #newsletter {
            display: none;
        }
        .text-fade-in {
            top: 24%;
            h1 {
                font-size: 40px;
            }
        }
        .custom-margin {
            margin-top: -48%;
        }

        .custom-height {
            height: 510px !important;
        }

    }

    @media (max-width: 320px) {
        .fc button { /* planner*/
            font-size: 0.7em !important;
        }
    }
