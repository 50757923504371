.openingstijden {
  table {
    width: 100%;
  }
  table, tr, td, th{
    border: 2px solid;
    padding: 10px;
  }
}

td{
  text-align: left;
}
