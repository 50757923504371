.top-nav {
    padding-top: 10px;
    padding-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.navbar-brand {
    color: #000;
    font-weight: 900;
}

.navbar-brand:hover {
    color: #e46e00;
}
.container-fluid {
    margin-top: 140px;
}

.nav {
    background-color: rgba(255, 255, 255, 1);
    padding: 5px;
}

.navbar-nav {
    width: 100%;
}

.navbar-light .navbar-nav .nav-link {
    color: #000;
}

.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-brand:hover, .navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-brand:focus {
    color: #000000;
}

.nav-item {
    margin: 0px 6px;
}

.nav-link {
    padding: .2rem 1rem;
    font-family: 'Source Sans Pro', sans-serif;
}
.nav-item {
    text-decoration: none;
}

.nav-item:first-child {
    margin-left: auto;
}

.nav-item:hover .nav-link {
    color: #a73c96 !important;
}

.nav-item .nav-link {
    color: #000;
}

.dropdown-menu {
    padding: 0 0;
    .dropdown-item {
        text-decoration: none;
        &:active{
            background-color: #a73c96;
        }
        &:focus {
            outline: none;
        }
        &:hover {
            background-color: #a73c96;
            color: #ffffff;
        }
    }
}

.nav-item:last-child {
    background-color: #a73c96;
    padding: 0px 5px;
    border: 2px solid #a73c96;
    .nav-link {
        color: #ffffff !important;
    }
}

.nav-item:last-child:hover {
    background-color: transparent;
    border: 2px solid #a73c96;
    .nav-link {
        color: #000000 !important;
    }
}


.active-menu {
    color: #a73c96 !important;
}

@media (max-width: 992px) {
    .nav {
        position: fixed;
        width: 110vw;
        left: 0;
        right: 0;
        .nav-item:last-child {
            padding: 5px;
        }
    }
    .fixed-top {
        top: 0 !important;
    }
    .nav-item:first-child {
        margin-left: 10px;
    }
    .active-menu:after {
        display: none;
    }
}
