
.btn-primary {
    background-color: #333333;
    color: #fff;
    border-radius: 0;
    border: 0px;
    &:hover {
        background-color: #e46e00;
    }
}

.btn-blue {
    background-color: #0091d3;
    border: 1px solid #0091d3;
    -webkit-box-shadow: 0px -2px 87px -11px rgba(0, 0, 0, 1);
    -moz-box-shadow: 0px -2px 87px -11px rgba(0, 0, 0, 1);
    box-shadow: 0px -2px 87px -11px rgba(0, 0, 0, 1);
    margin-top: 25px;
}

.btn-blue-2 {
    background-color: #0091d3;
    border: 1px solid #0091d3;
}

.btn-red {
    background-color: #f53c3c;
    border: 1px solid #f53c3c;
}

.btn-yellow {
    background-color: #f8de00;
    border: 1px solid #f8de00;
    color: #000;
}

.btn-purple {
    background-color: #A73C96;
    border: 1px solid #A73C96;
    position: relative;
    z-index: 99;
}

.btn-custom {
    padding: 10px 30px;
    border-radius: 25px;
    font-weight: bold;
    width: auto;
    &:hover {
        background-color: transparent;
        cursor: pointer;
        color: #000000;
    }
}

.newsletter-wrapper, .header-slider {
    .btn-custom {
        &:hover {
            color: #ffffff;
        }
    }
}

.btn-purple {
    border-radius: 0;
}